import React, { useMemo, useReducer, useEffect } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useQuery,
  ApolloProvider,
  gql,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import _ from 'lodash';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from './auth-context';
import { Center, Flex } from '@chakra-ui/react';

//
const SessionContext = React.createContext();
SessionContext.displayName = 'SessionContext';

// requête chargement des valeurs de session
const GET_SESSION = gql`
  query GET_SESSION {
    session {
      id
      application {
        id
        parametres
      }
      abonnement {
        id
        parametres
        logoUrl
        nomUsage
        territoirePrincipal {
          code
          id
          idNiv
          base
          detail {
            nom
            nomEnrichi
          }
        }
      }

      registre {
        id
        parametres
        territoirePrincipal {
          code
          id
          idNiv
          base
          detail {
            nom
            nomEnrichi
          }
        }
        preferences {
          menu {
            id
            titre
            slug
          }
          modalitesPossibles {
            id
            libelle
            slug
            valeurUnique
            valeurs {
              valeur
              libelle
              defaut
              complement
            }
          }
        }
      }
    }
  }
`;

//
// le reducer pour gérer le state de la session
//
const BWEB_TERRITOIRE_ETUDE_KEY = 'compas-bweb-territoire-etude';
const BWEB_TERRITOIRES_COMPARAISON_KEY = 'compas-bweb-territoires-comparaison';
const BWEB_PROPRIETES_KEY = 'compas-bweb-proprietes';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PROPRIETE':
      if (action.cle && action.valeur) {
        const proprietes = { ...state.proprietes, [action.cle]: action.valeur };
        window.localStorage.setItem(
          BWEB_PROPRIETES_KEY,
          JSON.stringify(proprietes),
        );

        return { ...state, proprietes };
      }
      return state;

    case 'SET_TERRITOIRE_ETUDE':
      window.localStorage.setItem(
        BWEB_TERRITOIRE_ETUDE_KEY,
        JSON.stringify(action.territoire),
      );
      return { ...state, territoireEtude: action.territoire };

    case 'SET_TERRITOIRES': {
      window.localStorage.setItem(
        BWEB_TERRITOIRE_ETUDE_KEY,
        JSON.stringify(action.territoireEtude),
      );
      window.localStorage.setItem(
        BWEB_TERRITOIRES_COMPARAISON_KEY,
        JSON.stringify(action.territoiresComparaison),
      );
      return {
        ...state,
        territoireEtude: action.territoireEtude,
        territoiresComparaison: action.territoiresComparaison,
      };
    }

    case 'SET_TERRITOIRES_ET_PROPRIETES': {
      window.localStorage.setItem(
        BWEB_TERRITOIRE_ETUDE_KEY,
        JSON.stringify(action.territoireEtude),
      );
      window.localStorage.setItem(
        BWEB_TERRITOIRES_COMPARAISON_KEY,
        JSON.stringify(action.territoiresComparaison),
      );

      let newProprietes = undefined;
      // console.log("action.cle", action.cle);
      // console.log("action.valeur", action.valeur);
      if (action.cle && action.valeur) {
        const proprietes = { ...state.proprietes, [action.cle]: action.valeur };
        window.localStorage.setItem(
          BWEB_PROPRIETES_KEY,
          JSON.stringify(proprietes),
        );
        newProprietes = { ...proprietes };
      }
      // console.log(
      //   "====> newProprietes ? newProprietes : state.proprietes ",
      //   newProprietes ? newProprietes : state.proprietes
      // );
      return {
        ...state,
        territoireEtude: action.territoireEtude,
        territoiresComparaison: action.territoiresComparaison,
        proprietes: newProprietes ? newProprietes : state.proprietes,
      };
    }

    case 'ADD_TERRITOIRE_COMPARAISON': {
      const terrToAdd = action.territoire;
      if (!terrToAdd) {
        console.warn('ADD_TERRITOIRE_COMPARAISON : territoire absent');
        return state;
      }
      if (
        !state.territoiresComparaison ||
        state.territoiresComparaison.length === 0
      ) {
        window.localStorage.setItem(
          BWEB_TERRITOIRES_COMPARAISON_KEY,
          JSON.stringify([terrToAdd]),
        );
        return { ...state, territoiresComparaison: [terrToAdd] };
      }
      const newTerr = [...state.territoiresComparaison];
      newTerr.push(terrToAdd);
      window.localStorage.setItem(
        BWEB_TERRITOIRES_COMPARAISON_KEY,
        JSON.stringify(newTerr),
      );
      return { ...state, territoiresComparaison: newTerr };
    }
    case 'REMOVE_TERRITOIRE_COMPARAISON': {
      const terrToRemove = action.territoire;
      const newTerr = _.filter(
        state.territoiresComparaison,
        (terr) => !_.isEqual(terr, terrToRemove),
      );
      window.localStorage.setItem(
        BWEB_TERRITOIRES_COMPARAISON_KEY,
        JSON.stringify(newTerr),
      );
      return { ...state, territoiresComparaison: newTerr };
    }

    case 'SET_SESSION_DATA':
      const localTerrEtude = window.localStorage.getItem(
        BWEB_TERRITOIRE_ETUDE_KEY,
      );
      const localTerrComp = window.localStorage.getItem(
        BWEB_TERRITOIRES_COMPARAISON_KEY,
      );

      const geographie = _.reduce(
        [
          action.session?.application?.parametres,
          action.session?.registre?.parametres,
          action.session?.abonnement?.parametres,
        ],
        function (r, i) {
          if (i && i.geographie) return { ...r, ...i.geographie };
          else return r;
        },
        {},
      );
      const babord = _.reduce(
        [
          action.session?.application?.parametres,
          action.session?.registre?.parametres,
          action.session?.abonnement?.parametres,
        ],
        function (r, i) {
          if (i && i.babord) return { ...r, ...i.babord };
          else return r;
        },
        {},
      );

      // le territoire principal
      // priorisation au registre s'il existe
      // sinon à l'abonnement
      const territoirePrincipal = action.session?.registre?.territoirePrincipal
        ? {
            ...action.session?.registre?.territoirePrincipal,
            nom:
              action.session?.registre?.territoirePrincipal.detail.nomEnrichi ||
              action.session?.registre?.territoirePrincipal.detail.nom,
          }
        : {
            ...action.session?.abonnement?.territoirePrincipal,
            nom:
              action.session?.abonnement?.territoirePrincipal.detail
                .nomEnrichi ||
              action.session?.abonnement?.territoirePrincipal.detail.nom,
          };

      return {
        ...state,
        ...action.session,
        abonnementDecouverte:
          action?.session?.abonnement?.parametres?.decouverte,
        preferences: action?.session?.registre?.preferences || [],
        geographie,
        babord,
        territoireEtude: localTerrEtude
          ? JSON.parse(localTerrEtude)
          : territoirePrincipal
            ? territoirePrincipal
            : initialState.territoireEtude,
        territoiresComparaison: localTerrComp
          ? JSON.parse(localTerrComp)
          : initialState.territoiresComparaison,
      };

    default:
      return state;
  }
}

//
const initialState = {
  territoireEtude: undefined,
  territoiresComparaison: [],
  application: undefined,
  abonnement: undefined,
  registre: undefined,
  geographie: {},
  babord: {},
  proprietes: {},
  preferences: [],
  abonnementDecouverte: false,
};

/**
 *
 * @param {*} props
 * @returns
 */
function SessionProvider({ onAuthError, children }) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initialState) => {
      const localTerrEtude = window.localStorage.getItem(
        BWEB_TERRITOIRE_ETUDE_KEY,
      );
      const newInitialState = {
        ...initialState,
        territoireEtude: localTerrEtude
          ? JSON.parse(localTerrEtude)
          : initialState.territoireEtude,
      };
      // console.log("newInitialState", newInitialState);
      return newInitialState;
    },
  );
  const { loading, data, error } = useQuery(GET_SESSION, {
    nextFetchPolicy: 'cache-only',
  });

  // on actualise les données de session
  useEffect(() => {
    if (data?.session) {
      dispatch({ type: 'SET_SESSION_DATA', session: data?.session });
    }
    return () =>
      dispatch({ type: 'SET_SESSION_DATA', session: { ...initialState } });
  }, [data]);

  if (loading) return <div>chargement session...</div>;
  if (error)
    return (
      <Center>
        <Flex flexDir={'column'}>
          <div>
            <p style={{ color: 'red' }}>Erreur : </p>
            <div style={{ color: 'rgb(100,100,100)', padding: '5px' }}>
              {error.message}
            </div>
          </div>
          <p style={{ display: 'inline', fontWeight: '600' }}></p>
          <button
            style={{
              fontWeight: '600',
              padding: '10px',
              backgroundColor: 'var(--chakra-colors-compasLogo-bleu)',
              color: 'white',
            }}
            onClick={onAuthError}
          >
            Identifiez à nouveau
          </button>
        </Flex>
      </Center>
    );

  // les callback pour modifier le territoire d'étude et les territoires dit de comparaison
  const setTerritoireEtude = (territoire) => {
    dispatch({ type: 'SET_TERRITOIRE_ETUDE', territoire });
  };
  const addTerritoireComparaison = (territoire) => {
    // console.log('addTerritoireComparaison', territoire);
    dispatch({ type: 'ADD_TERRITOIRE_COMPARAISON', territoire });
  };
  const setTerritoires = (territoireEtude, territoiresComparaison) => {
    dispatch({
      type: 'SET_TERRITOIRES',
      territoireEtude,
      territoiresComparaison,
    });
  };
  const setTerritoiresEtProprietes = (
    territoireEtude,
    territoiresComparaison,
    cle,
    valeur,
  ) => {
    dispatch({
      type: 'SET_TERRITOIRES_ET_PROPRIETES',
      territoireEtude,
      territoiresComparaison,
      cle,
      valeur,
    });
  };

  const removeTerritoireComparaison = (territoire) => {
    dispatch({ type: 'REMOVE_TERRITOIRE_COMPARAISON', territoire });
  };
  const setPropriete = (cle, valeur) => {
    dispatch({ type: 'SET_PROPRIETE', cle, valeur });
  };
  const getPropriete = (cle) => {
    return cle && state && state.proprietes && state.proprietes[cle];
  };

  return (
    <SessionContext.Provider
      value={{
        ...state,

        territoireEtude: state.territoireEtude,
        territoiresComparaison: state.territoiresComparaison,
        removeTerritoireComparaison,
        addTerritoireComparaison,
        setTerritoireEtude,
        setTerritoires,
        setTerritoiresEtProprietes,
        setPropriete,
        getPropriete,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

/**
 *
 * @param {*} props
 */
function ApiClientProvider({ onAuthError, children }) {
  const { token, estConnecte } = useAuth();

  const client = useMemo(() => {
    if (!estConnecte || !token) return;

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjllYmJlNTMzLTI4NzYtNGQwYS1hMjI5LTE3OWMwMDFlYjNlZSIsImNvbm5leGlvbklkIjoiY29tcGFzIiwicHJvZmlsIjoiIiwiaWF0IjoxNTkxOTU0MDg5LCJleHAiOjE1OTQ1NDYwODl9.NXWw4GSN0TW1tn7ofRdIoKIifZbtjEu-8SPtFu07XwA";
      // localStorage.getItem('token');
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    // TODO: utiliser ce link pour capter les erreurs réseaux
    // https://www.apollographql.com/docs/react/data/error-handling/#gatsby-focus-wrapper
    // https://www.apollographql.com/docs/react/api/link/introduction/#composing-a-link-chain

    const cheminNom = {
      read(_, { readField, toReference }) {
        const cheminId = readField('cheminId');
        return cheminId.map((id) =>
          readField('nom', toReference({ __typename: 'Categorie', id })),
        );
      },
    };

    const datefinfr = {
      read(_, { readField }) {
        const [y, m, d] = readField('datefin').split('-');
        return `${d}/${m}/${y}`;
      },
    };

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions?.code === 'UNAUTHENTICATED') {
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          } else
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
        });

      if (networkError) console.error(`[Network error]: ${networkError}`);
    });
    const client = new ApolloClient({
      connectToDevTools: process.env.NODE_ENV !== 'production',
      cache: new InMemoryCache({
        typePolicies: {
          ResultatRecherche: {
            fields: {
              cheminNom,
            },
          },
          Categorie: {
            fields: {
              cheminNom,
            },
          },
          GetDatesReturn: {
            fields: { datefinfr },
          },
          Valeur: {
            fields: { datefinfr },
          },
        },
      }),
      link: from([
        authLink,
        errorLink,
        new HttpLink({
          uri: process.env.REACT_APP_BWEB_SERVER,
        }),
      ]),
    });
    return client;
  }, [estConnecte, token]);

  if (!client) {
    return null;
  }
  return (
    <ApolloProvider client={client}>
      <SessionProvider onAuthError={onAuthError}>{children} </SessionProvider>
    </ApolloProvider>
  );
}

const useSession = () => React.useContext(SessionContext);
export { ApiClientProvider, useSession };
